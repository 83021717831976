import moment from "moment-timezone"

/**
 * @object jadi ini dipake kalo mau nge format date ya ges, bisa ditambahin juga kalo mau nambah ya ges biar ndak liar 
 */
export const dateStringFormat = {
  /**
   * @returns 22 Aug 2023, 23:32 (UTC+07:00)
   */
  global: 'DD MMM YYYY, HH:mm (UTCZ)',
  /**
   * @returns 22 August 2023
   */
  birthdate: 'DD MMMM YYYY',
  /**
   * @returns 3 July 2024 11:55 AM
   */
  common: 'D MMMM YYYY h:mm A',
  /**
   * @returns 3 July 2024 11:55 AM
   */
  chat: 'h:mm A',
  /**
   * @returns 18 Jan '23 (Short year format)
   */
  shortYear: "D MMM 'YY"
}

/**
 * 
 * @name globalDateFormat
 * @description jadi gini, date formatter ini pasti date required doong
 * 
 * @requires @param {Date|string} date 
 * ya mesti required toyoo, tros meh format apa nek kosongan ? budhe !?
 * datetime ya ges
 * 
 * @optioanl @param {Object} options 
 * @optioanl @param {string} options.format 
 * options digunakan untuk additional stuff
 * misal  :
 *  - format digunakan untuk memformat output, bisa menggunakan @object dateStringFormat 
 * 
 * @returns {'22 June 2023, 09:48:28 (UTC+07:00)'}
 */
export const globalDateFormat = (date, options = { format: null, utcOffset: null }) => {
  let formattedDate = moment(date)
  if (options.utcOffset) formattedDate = formattedDate.utcOffset(options.utcOffset)
  return formattedDate.format(options.format ?? dateStringFormat.global)
}

/**
 * ꦥꦿꦶꦱꦶꦢꦶꦏꦤ꧀ ꦥꦸꦭꦶꦪꦤ꧀ ꦢꦼꦤ꧀ꦝꦶꦁ ꦢꦼꦤ꧀ꦝꦺꦴꦥꦼꦂ ꦱꦼꦩꦶꦤ꧀
 * ꦥꦺꦴꦤ꧀ꦝꦺꦴꦥ꦳ꦸꦏꦸꦩꦸꦭꦶ ꦱꦏꦼꦠꦺꦤꦶ ꦥꦼꦤꦶꦠꦼ
 * 
 * @param {Date} start - ꦥꦿꦶꦪꦤ꧀ ꦧꦼꦩ ꦢꦺꦴꦏꦺꦴꦢ ꦥꦼꦤꦶꦠꦼ
 * @param {Date} end - ꦥꦿꦶꦪꦤ꧀ ꦧꦼꦩ ꦧꦭꦲꦶꦏꦺꦴꦏꦺꦴꦢ ꦥꦼꦤꦶꦠꦼ
 * @returns {string} ꦤꦶꦭꦺꦴꦁꦏꦼꦭꦺꦴꦢꦺꦴꦏꦺꦴꦢ ꦥꦼꦤꦶꦠꦼ
 * @by Cah Bagoes
 */
export function formatDateRange(start, end) {
  /**
   * ꦥꦿꦶꦱꦶꦢꦶꦏꦤ꧀ ꦥꦸꦭꦶꦪꦤ꧀ ꦢꦼꦤ꧀ꦝꦶꦁ ꦢꦼꦤ꧀ꦝꦺꦴꦥꦼꦂ
   * 
   * @param {Date} date - ꦢꦼꦒꦺꦴꦩꦸꦏꦼꦩ꧀ ꦥꦼꦤꦶꦠꦼ
   * @returns {string} ꦤꦶꦭꦺꦴꦁꦏꦼꦭꦺꦴꦢꦺꦴꦏꦺꦴꦢ ꦥꦼꦤꦶꦠꦼ
   */
  const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short', year: '2-digit' };
    return date.toLocaleDateString('en-GB', options).replace(',', '').replace('20', "'");
  };

  /**
   * ꦥꦿꦶꦱꦶꦢꦶꦏꦤ꧀ ꦱꦺꦭꦶꦤꦶ ꦏꦼꦭꦺꦴ
   * 
   * @param {Date} date - ꦢꦼꦒꦺꦴꦩꦸꦏꦼꦩ꧀ ꦥꦼꦤꦶꦠꦼ
   * @returns {string} ꦥꦼꦤꦶꦠꦼ ꦏꦼꦭꦺꦴ
   */
  const formatTime = (date) => {
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
  };

  return `${formatDate(start)} | ${formatTime(start)} - ${formatTime(end)}`;
}
