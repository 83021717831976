import dayjs from "dayjs";
import { dateStringFormat, globalDateFormat } from "./dateFormatter";

export const formatDateTime = (utcString) => {
    if (!utcString) return "-"
    // return dayjs(utcString).format("d MMM ''YY, h:mm A").replace(":00", "") ?? "-"
    // return dayjs(utcString).format("D MMM YYYY HH:mm:ss").replace(":00", "") ?? "-"
    return globalDateFormat(utcString)
}

export const capitalizeFirstLetter = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : undefined
}

export function formatPriceToString(amount, decimals = 2) {
    const factor = Math.pow(10, decimals);
    // round down price not up
    const roundedAmount = Math.floor(amount * factor) / factor;

    const formattedPrice = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    }).format(roundedAmount);

    return formattedPrice.replace(".00", "");
}

export const formatVenue = (venue) => {
    if (!venue) return "-"
    return [venue?.address, venue?.city, venue?.country, venue?.postalCode].filter(Boolean).join(", ")
}
