import React from 'react';

// Buat context
const ProfileContext = React.createContext();

// Buat provider untuk context
export const ProfileDetailProvider = ({ children }) => {
  const [profile, setProfile] = React.useState(null);

  // Fungsi untuk mengatur profil
  const updateProfile = (profileData) => {
    setProfile(profileData);
  };

  // Fungsi untuk mengambil profil
  const getProfile = () => {
    return profile;
  };

  return (
    <ProfileContext.Provider value={{ profile, updateProfile, getProfile }}>
      {children}
    </ProfileContext.Provider>
  );
};

// Custom hook untuk menggunakan context
export const useProfile = () => {
  return React.useContext(ProfileContext);
};