import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import "./scss/volt.scss";
import "./css/global.css"

// vendor styles
import "react-datetime/css/react-datetime.css";
import 'react-toastify/dist/ReactToastify.css';

import MainRoute from './routes/index.route';
import { AuthProvider, ProtectRoute } from './data/context/auth';
import { RequestManagerProvider } from 'services/RequestManagerContext';
import { ProfileDetailProvider } from 'contexts/profile_detail_ctx';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <BrowserRouter>
    <RequestManagerProvider>
      <AuthProvider>
        <ProfileDetailProvider>
          <ProtectRoute>
            <MainRoute />
          </ProtectRoute>
        </ProfileDetailProvider>
      </AuthProvider>
    </RequestManagerProvider>
  </BrowserRouter>
);
